import { onReady } from './utils/onReady';
import { isTouch } from './utils/helpers';
import { setupNavigation } from './layout/navigation';
import { setupForm } from './layout/form';
import { setupModules } from './modules/modules';

function init() {
    document.body.classList.remove('standby');

    // Detect touch
    if (isTouch) {
        document.body.classList.add('touch-device');
    }

    onReady(() => {
        setupNavigation();
        setupForm();
        setupModules();
    });
}

init();
