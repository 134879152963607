function infoAccordion() {
    
    const infoCarouselChoser = document.querySelectorAll('article[data-type="accordion"]');

    // infoCarouselChoser.forEach(article => {
        
    //     const articleContent = article.getElementsByClassName("info-accordion__wrapper")[0];
    //     const infoAccordionItemList = Array.from(articleContent.getElementsByClassName('info-accordion__list')[0].children);
    //     const infoDisplayContainer = Array.from(articleContent.getElementsByClassName('info-accordion__column'));
    //     const infoDisplayList = infoDisplayContainer[1].children;

    //     function toggleShowInfo(li) {
    //         // remove all former .active
    //         for (let d = 0 ; d < infoDisplayList.length ; d++) {
    //             infoDisplayList[d].classList.remove('active');
    //             infoAccordionItemList[d].classList.remove('active');
    //         }
    
    //         // add new .active to clicked LI  & corresponding data-counter
    //         const dataKey = li.children[0].getAttribute('href');
    //         li.classList.add('active');
    //         infoDisplayContainer[1].querySelector(`[data-key="${dataKey}"`).classList.toggle('active');
    //     }
    
    //     infoAccordionItemList.forEach(li => {
            
    //         li.addEventListener('click', function(e) {
    //             toggleShowInfo(li);
                
    //         });
    //     });
    // });

    for (let c = 0; c < infoCarouselChoser.length; c++) {
        const infoAccordionElement = infoCarouselChoser[c];
        const articleContent = infoAccordionElement.getElementsByClassName("info-accordion__wrapper")[0];
        const infoAccordionItemList = Array.from(articleContent.getElementsByClassName('info-accordion__list')[0].children);
        const infoDisplayContainer = Array.from(articleContent.getElementsByClassName('info-accordion__column'));
        const infoDisplayList = infoDisplayContainer[1].children;

        function toggleShowInfo(li) {
            // remove all former .active
            for (let d = 0 ; d < infoDisplayList.length ; d++) {
                infoDisplayList[d].classList.remove('active');
                infoAccordionItemList[d].classList.remove('active');
            }
    
            // add new .active to clicked LI  & corresponding data-counter
            const dataKey = li.children[0].getAttribute('href');
            li.classList.add('active');
            infoDisplayContainer[1].querySelector(`[data-key="${dataKey}"`).classList.toggle('active');
        }
        
        for (let i = 0; i < infoAccordionItemList.length; i++) {
            infoAccordionItemList[i].addEventListener('click', function(e) {
                toggleShowInfo(infoAccordionItemList[i]);
            });
        }
    }

}

export {infoAccordion};
