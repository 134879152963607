export function setupNavigation()
{
    const burger = document.querySelector('#nav-burger-icon');
    const body = document.querySelector('body');
    if(burger != null)
    {
        burger.addEventListener('click', function (e)
        {
            if(body.classList.contains('mobile--nav'))
            {
                body.classList.remove('mobile--nav');
            }
            else
            {
                body.classList.add('mobile--nav');
                burger.focus();
            }
            return false;
        });
    }
}
