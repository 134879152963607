export function setupForm() {

    const formInput = document.querySelectorAll('.umbraco-forms-form textarea, .umbraco-forms-form input');

    for (let i = 0; i < formInput.length; i++) {

        formInput[i].addEventListener('keyup', function () {
            var val = this.value ?? '';
            if (val !== '') {
                if (!this.classList.contains('input--has-value')) {
                    this.classList.add('input--has-value');
                }
            } else {
                this.classList.remove('input--has-value');
            }
        });
    }
}
