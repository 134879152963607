import { fgParallax } from './../utils/parallax';
import { headerParallax } from './../layout/header';
import { infoAccordion } from './../layout/infoAccordion';

export function setupModules() {

    let scrollTimerId;
    let resizeTimerId;
    let firstScroll = true;

    function checkVisibleArticles() {
        const articles = document.querySelectorAll('main article, main header');

        for (let i = 0; i < articles.length; i++) {
            const article = articles[i];
            const type = article.getAttribute('data-type');

            if (isElementPartlyInViewport(article)) {
                switch (type) {
                    case 'textImage':
                    case 'productLine':
                        fgParallax(article);

                        if (!article.classList.contains('element--visible')) {
                            article.classList.add('element--visible');
                            const iframe = article.querySelector('iframe');

                            if (iframe) {
                                const player = new Vimeo.Player(iframe);
                                player.play().catch(function (e) {});;
                            }
                        }

                        if (type === 'productLine') {
                            const logoBlock = article.querySelector('.lifestyle-block__logo');

                            if (logoBlock != null && !logoBlock.classList.contains('has-animated-once'))
                            {
                                const logo = logoBlock.querySelector('svg');

                                if (logo && isElementPartlyInViewport(logoBlock)) {
                                    logoBlock.classList.add('has-animated-once');

                                    if (!logo.classList.contains('svg-animate')) {
                                        setTimeout(() => {
                                            logo.classList.add('svg-animate');
                                        }, 1000);
                                    }
                                }
                            }
                        }

                        break;
                    case 'header':
                        headerParallax();
                        break;
                }
            }
            else {
                article.classList.remove('element--visible');

                switch (type) {
                    case 'textImage':
                    case 'productLine':
                        const iframe = article.querySelector('iframe');
                        if (iframe) {
                            const player = new Vimeo.Player(iframe);
                            player.pause().catch(function (e) {
                            });;
                        }
                        break;
                }
            }
        }
    };

    function isElementPartlyInViewport(el) {
        let top = el.offsetTop;
        let left = el.offsetLeft;
        const width = el.offsetWidth;
        const height = el.offsetHeight;

        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        return (
            top < (window.pageYOffset + window.innerHeight) &&
            left < (window.pageXOffset + window.innerWidth) &&
            (top + height) > window.pageYOffset &&
            (left + width) > window.pageXOffset
        );
    };

    window.addEventListener('scroll', function () {
        if (firstScroll == false) {
            clearTimeout(scrollTimerId);
            scrollTimerId = setTimeout(checkVisibleArticles, 1);
        }
    });

    scrollTimerId = setTimeout(checkVisibleArticles, 1000);
    firstScroll = false;

    window.addEventListener('resize', function () {
        clearTimeout(resizeTimerId);
        resizeTimerId = setTimeout(checkVisibleArticles, 1000);
    });

    infoAccordion();
}
